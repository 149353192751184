<template>
	<section class="container">
		<div class="row">
			<div class="col-12">
				<BaseTable :items="employeeInsurances" :fields="fields" :isBusy="false">
					<template v-slot:cell(supplier)="data">
						{{ getSupplierName(data.item.insuranceId) }}
					</template>
					<template v-slot:cell(name)="data">
						<router-link
							:to="{
								name: 'CompanyEmployeeInsurance',
								params: { insuranceId: data.item.insuranceId },
							}"
							>{{ getInsuranceName(data.item.insuranceId) }}</router-link
						>
					</template>
				</BaseTable>
			</div>
		</div>
	</section>
</template>

<script>
import BaseTable from '@/modules/global/components/BaseTable'
import dateMixin from '@/mixins/dateMixin'
export default {
	name: 'CompanyEmployeeInsuranceList',
	mixins: [dateMixin],
	components: {
		BaseTable,
	},
	data() {
		return {
			globalfilter: '',
			fields: [
				{
					key: 'name',
					label: 'Aftalenavn',
					sortable: true,
				},
				{
					key: 'supplier',
					label: 'Leverandør',
					sortable: true,
				},
			],
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		getSupplierName(insuranceId) {
			var companyInsurance = this.getCompanyInsurance(insuranceId)
			var supplierId = companyInsurance.insurance.supplierId
			const supplier = this.suppliers.find((g) => g.id === supplierId)
			return supplier?.name ?? ''
		},
		getInsuranceName(insuranceId) {
			var companyInsurance = this.getCompanyInsurance(insuranceId)
			return companyInsurance.insurance.name
		},
		getCompanyInsurance(insuranceId) {
			return this.companyInsurances.find((g) => g.id === insuranceId)
		},
	},
	computed: {
		employeeInsurances() {
			return this.$store.getters['employeeVuex/employeePolicy'].insurances
		},
		companyInsurances() {
			return this.company.insurances
		},
		companyId() {
			return this.$route.params.companyId
		},
        employeeId() {
            return this.$route.params.employeeId
        },
		company() {
			var company = this.$store.getters['employeeVuex/company']
			return company
		},
		isBusy() {
			return !this.$store.getters['employeeVuex/isReady']
		},
		suppliers() {
			return this.$store.getters['supplier/suppliers']
		},
	},
}
</script>

<style lang="scss"></style>
